<template>
  <div class="tw-p-4">
    <div class="tw-flex tw-justify-end">
      <button
        type="button"
        title="Niveaubeheer"
        class="tw-px-2 tw-py-0.5 tw-rounded tw-bg-success tw-text-white hover:tw-bg-darken-success"
        @click="showLevelManagementModal"
      >
        <i class="fas fa-cog" /> Niveaubeheer
      </button>
    </div>

    <!-- We use @dragover.prevent event to prevent default dragover behavior to allow dropping -->
    <div
      v-for="({ level, rooms }, levelIndex) in levels"
      :key="level.id || 'no-level'"
      class="tw-my-6 tw-px-6 tw-py-8 tw-rounded tw-border tw-border-gray-e9"
      @drop.prevent="onDrop($event, level.id, levelIndex)"
      @dragover.prevent
    >
      <div class="tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-gap-4">
        <h1 class="tw-my-0">{{ level.name }}</h1>
        <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
          <button
            type="button"
            title="Toevoegen"
            class="tw-px-2 tw-py-0.5 tw-rounded tw-bg-success tw-text-white hover:tw-bg-darken-success"
            @click="showCreateEditModal({ property_level: level.id })"
          >
            <i class="fas fa-plus" /> Ruimte
          </button>
          <button
            type="button"
            title="Meerdere ruimtes"
            class="tw-px-2 tw-py-0.5 tw-rounded tw-bg-gray-500 tw-text-white hover:tw-bg-gray-600"
            @click="showQuickEntryModal({ property_level: level.id })"
          >
            <i class="fas fa-plus" /> Meerdere ruimtes
          </button>
          <button
            v-if="level.id"
            type="button"
            title="Verwijder niveau"
            class="tw-px-2 tw-py-0.5 tw-rounded tw-text-white tw-bg-danger hover:tw-bg-darken-danger"
            @click="removeLevel(level.id)"
          >
            <i class="fas fa-trash" />
          </button>
        </div>
      </div>

      <div class="tw-mt-8 tw-grid lg:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-8">
        <div
          v-for="(room, roomIndex) in rooms"
          :key="room.id"
          :draggable="!updatingRoom[room.id]"
          :class="[
            { 'tw-animate-pulse tw-pointer-events-none': updatingRoom[room.id] },
            'tw-relative tw-flex tw-gap-2 tw-rounded-md tw-shadow-card tw-flex-grow tw-cursor-move'
          ]"
          @dragstart="onDragStart($event, room, level.id, levelIndex, roomIndex)"
        >
          <div class="tw-absolute tw-flex tw-flex-col tw-gap-3 tw-right-0 tw-top-0 tw--translate-y-2 tw-translate-x-1/2">
            <button
              type="button"
              title="Verwijderen"
              class="action-button tw-text-success tw-border-success hover:tw-bg-success"
              @click="showCreateEditModal({ property_level: level.id, ...room })"
            >
              <i class="fas fa-pencil" />
            </button>
            <button
              type="button"
              title="Verwijderen"
              :disabled="room.deleting"
              class="action-button tw-text-danger tw-border-danger hover:tw-bg-danger"
              @click="deleteRoom({ property_level: level.id, ...room })"
            >
              <i
                :class="[
                  'fas',
                  room.deleting
                    ? 'fa-spinner-third fa-spin'
                    : 'fa-trash'
                ]"
              />
            </button>
          </div>

          <div class="tw-w-24 sm:tw-w-32 tw-overflow-hidden tw-rounded-l-md tw-flex-shrink-0">
            <img
              v-if="room.picture"
              :src="room.picture.url_thumbnail"
              class="tw-h-full tw-w-full tw-object-contain"
            />
            <img
              v-else
              src="https://iam-media-prod.s3.eu-central-1.amazonaws.com/property_dummy.gif"
              class="tw-h-full tw-w-full tw-object-cover"
            />
          </div>

          <div class="tw-p-2">
            <h4 class="tw-mb-1 tw-text-xs">
              {{ room.type_display }}
              -
              <span class="tw-my-1">
                {{ room.surface }} m<sup>2</sup>
              </span>
            </h4>

            <strong>{{ roomTitle(room) || 'geen titel' }}</strong>
            <p :title="roomDescription(room)" class="tw-mb-0 tw-italic tw-break-words">
              {{ roomDescription(room) | truncate(90) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <NewPropertyRoomQuickEntryModal
      ref="quickEntryModal"
      @reload="loadPropertyRooms"
    />
    <PropertyRoomCreateEditModal
      ref="createEditModal"
      @reload="loadPropertyRooms"
    />
    <BaseModal ref="levelManagementModal" title="Niveaubeheer" max-width="tw-max-w-sm">
      <FormulateForm
        #default="{ isLoading }"
        name="levelManagementForm"
        @submit="updateSelectedPropertyLevels"
      >
        <FormulateInput
          v-model="selectedLevels"
          type="checkbox"
          name="level_ids"
          :options="levelOptions"
        />

        <FormulateErrors />
        <FormulateInput
          type="submit"
          :disabled="isLoading"
          outer-class="tw-mt-8"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
      </FormulateForm>
    </BaseModal>
  </div>
</template>

<script>
import { errorModal, questionModal, successModal } from '@/modalMessages'

import NewPropertyRoomQuickEntryModal from '@/components/properties/NewPropertyRoomQuickEntryModal'
import PropertyRoomCreateEditModal from '@/components/properties/PropertyRoomCreateEditModal'

import {
  getPropertyRoomsOverview,
  updatePropertyLevelsBulk,
  getPropertyLevelsBulk,
  deletePropertyLevel,
  deletePropertyRoom,
  updatePropertyRoom
} from '@/services/properties'

export default {
  name: 'PropertyRooms',
  components: {
    NewPropertyRoomQuickEntryModal,
    PropertyRoomCreateEditModal
  },
  data () {
    return {
      roomTypes: {},
      levels: [],
      updatingRoom: {},
      levelOptions: [],
      selectedLevels: [],
      levelOptionsWithRooms: []
    }
  },
  computed: {
    propertyId () {
      return this.$route.params.id
    }
  },
  created () {
    this.loadPropertyRooms()
  },
  methods: {
    showCreateEditModal (room) {
      this.$refs.createEditModal.show(room)
    },
    showQuickEntryModal (payload) {
      this.$refs.quickEntryModal.show(payload)
    },

    showLevelManagementModal () {
      this.getPropertyLevelOptions()
      this.$refs.levelManagementModal.show()
    },
    hideLevelManagementModal () {
      this.$refs.levelManagementModal.hide()
    },

    roomTitle (room) {
      const title = room?.descriptions?.filter((desc) => desc.language === 'nl')
      if (title?.length) return title[0].title
      return ''
    },
    roomDescription (room) {
      const description = room?.descriptions?.filter((desc) => desc.language === 'nl')
      if (description?.length) return description[0].description
      return ''
    },

    onDragStart (event, room, levelId, oldLevelIndex, roomIndex) {
      // Set the indices for the source room
      const indices = { oldLevelIndex, roomIndex }

      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('levelId', levelId)

      event.dataTransfer.setData('room', JSON.stringify(room))
      event.dataTransfer.setData('indices', JSON.stringify(indices))
    },

    async onDrop (event, newLevelId, newLevelIndex) {
      const prevLevelId = event.dataTransfer.getData('levelId')
      const room = JSON.parse(event.dataTransfer.getData('room'))
      const indices = JSON.parse(event.dataTransfer.getData('indices'))

      // Don't allow dropping on the same level, since one can be int, null, or string, we convert both to String
      if (String(prevLevelId) === String(newLevelId)) return false
      const response = await this.moveRoom(room, newLevelId, { newLevelIndex, ...indices })
      return response
    },
    async moveRoom (room, property_level, indices) {
      try {
        this.$set(this.updatingRoom, room.id, true)
        const { newLevelIndex, oldLevelIndex, roomIndex } = indices

        const response = await updatePropertyRoom(this.propertyId, room.id, { property_level })

        this.levels[newLevelIndex].rooms.push(room)
        this.levels[oldLevelIndex].rooms.splice(roomIndex, 1)

        return response
      } catch (error) {
        console.error('Unable to move the room', error)
      } finally {
        this.$set(this.updatingRoom, room.id, false)
      }
    },

    async getPropertyLevelOptions () {
      const response = await getPropertyLevelsBulk(this.propertyId)
      const levels = response.data || []

      this.selectedLevels = []
      this.levelOptionsWithRooms = []

      this.levelOptions = levels.map(level => {
        const { id, name, selected, has_rooms } = level
        if (selected) this.selectedLevels.push(String(id)) // We push string as the HTML input also makes the selection a string, and this keeps things consistent
        if (has_rooms) this.levelOptionsWithRooms.push(level)
        return { value: id, label: name }
      })

      return response
    },
    async updateSelectedPropertyLevels ({ level_ids }) {
      try {
        // Checks if any rooms with levels were unselected, then creates a new array with the names of those levels
        const levelsWithRoomsRemoved = this.levelOptionsWithRooms
          .filter(level => !level_ids.includes(String(level.id))) // Since the level_ids would likely be strings too.
          .map(level => level.name)

        if (levelsWithRoomsRemoved.length) {
          const result = await questionModal(
            `
              Er zijn ruimtes gelinkt aan de volgende niveaus: <b>${levelsWithRoomsRemoved.join(', ')}</b>.
              <br/>
              Bevestig hierbij dat je de niveaus wenst te verwijderen. De ruimtes blijven behouden, maar zullen niet meer ingedeeld zijn.
            `
          )
          if (!result.value) return
        }

        const response = await updatePropertyLevelsBulk(this.propertyId, { level_ids })
        await this.loadPropertyRooms()
        this.hideLevelManagementModal()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'levelManagementForm')
      }
    },
    async loadPropertyRooms () {
      const response = await getPropertyRoomsOverview(this.propertyId)
      this.levels = response.data || []
      return response
    },
    async removeLevel (levelId) {
      try {
        const result = await questionModal('Weet je zeker dat je dit niveau wil verwijderen?')
        if (!result.value) return

        const response = await deletePropertyLevel(this.propertyId, levelId)
        await this.loadPropertyRooms()
        successModal('Niveau is verwijderd.')
        return response
      } catch (error) {
        errorModal('Er ging iets mis bij het verwijderen van het niveau.')
        throw error
      }
    },
    async deleteRoom (room) {
      try {
        const result = await questionModal('Weet je zeker dat je deze ruimte wil verwijderen?')
        if (!result.value) return

        this.$set(room, 'deleting', true)
        const response = await deletePropertyRoom(this.propertyId, room.id)
        await this.loadPropertyRooms()
        successModal(`${room.type_display} met titel ${this.roomTitle(room)} is verwijderd.`)
        return response
      } catch (error) {
        errorModal('Er ging iets mis bij het verwijderen van de ruimte.')
        throw error
      } finally {
        this.$set(room, 'deleting', false)
      }
    }
  }
}
</script>
