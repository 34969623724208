var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-p-4"},[_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('button',{staticClass:"tw-px-2 tw-py-0.5 tw-rounded tw-bg-success tw-text-white hover:tw-bg-darken-success",attrs:{"type":"button","title":"Niveaubeheer"},on:{"click":_vm.showLevelManagementModal}},[_c('i',{staticClass:"fas fa-cog"}),_vm._v(" Niveaubeheer ")])]),_vm._l((_vm.levels),function(ref,levelIndex){
var level = ref.level;
var rooms = ref.rooms;
return _c('div',{key:level.id || 'no-level',staticClass:"tw-my-6 tw-px-6 tw-py-8 tw-rounded tw-border tw-border-gray-e9",on:{"drop":function($event){$event.preventDefault();return _vm.onDrop($event, level.id, levelIndex)},"dragover":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-gap-4"},[_c('h1',{staticClass:"tw-my-0"},[_vm._v(_vm._s(level.name))]),_c('div',{staticClass:"tw-flex tw-flex-wrap tw-items-center tw-gap-2"},[_c('button',{staticClass:"tw-px-2 tw-py-0.5 tw-rounded tw-bg-success tw-text-white hover:tw-bg-darken-success",attrs:{"type":"button","title":"Toevoegen"},on:{"click":function($event){return _vm.showCreateEditModal({ property_level: level.id })}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Ruimte ")]),_c('button',{staticClass:"tw-px-2 tw-py-0.5 tw-rounded tw-bg-gray-500 tw-text-white hover:tw-bg-gray-600",attrs:{"type":"button","title":"Meerdere ruimtes"},on:{"click":function($event){return _vm.showQuickEntryModal({ property_level: level.id })}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Meerdere ruimtes ")]),(level.id)?_c('button',{staticClass:"tw-px-2 tw-py-0.5 tw-rounded tw-text-white tw-bg-danger hover:tw-bg-darken-danger",attrs:{"type":"button","title":"Verwijder niveau"},on:{"click":function($event){return _vm.removeLevel(level.id)}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()])]),_c('div',{staticClass:"tw-mt-8 tw-grid lg:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-8"},_vm._l((rooms),function(room,roomIndex){return _c('div',{key:room.id,class:[
          { 'tw-animate-pulse tw-pointer-events-none': _vm.updatingRoom[room.id] },
          'tw-relative tw-flex tw-gap-2 tw-rounded-md tw-shadow-card tw-flex-grow tw-cursor-move'
        ],attrs:{"draggable":!_vm.updatingRoom[room.id]},on:{"dragstart":function($event){return _vm.onDragStart($event, room, level.id, levelIndex, roomIndex)}}},[_c('div',{staticClass:"tw-absolute tw-flex tw-flex-col tw-gap-3 tw-right-0 tw-top-0 tw--translate-y-2 tw-translate-x-1/2"},[_c('button',{staticClass:"action-button tw-text-success tw-border-success hover:tw-bg-success",attrs:{"type":"button","title":"Verwijderen"},on:{"click":function($event){return _vm.showCreateEditModal(Object.assign({}, {property_level: level.id}, room))}}},[_c('i',{staticClass:"fas fa-pencil"})]),_c('button',{staticClass:"action-button tw-text-danger tw-border-danger hover:tw-bg-danger",attrs:{"type":"button","title":"Verwijderen","disabled":room.deleting},on:{"click":function($event){return _vm.deleteRoom(Object.assign({}, {property_level: level.id}, room))}}},[_c('i',{class:[
                'fas',
                room.deleting
                  ? 'fa-spinner-third fa-spin'
                  : 'fa-trash'
              ]})])]),_c('div',{staticClass:"tw-w-24 sm:tw-w-32 tw-overflow-hidden tw-rounded-l-md tw-flex-shrink-0"},[(room.picture)?_c('img',{staticClass:"tw-h-full tw-w-full tw-object-contain",attrs:{"src":room.picture.url_thumbnail}}):_c('img',{staticClass:"tw-h-full tw-w-full tw-object-cover",attrs:{"src":"https://iam-media-prod.s3.eu-central-1.amazonaws.com/property_dummy.gif"}})]),_c('div',{staticClass:"tw-p-2"},[_c('h4',{staticClass:"tw-mb-1 tw-text-xs"},[_vm._v(" "+_vm._s(room.type_display)+" - "),_c('span',{staticClass:"tw-my-1"},[_vm._v(" "+_vm._s(room.surface)+" m"),_c('sup',[_vm._v("2")])])]),_c('strong',[_vm._v(_vm._s(_vm.roomTitle(room) || 'geen titel'))]),_c('p',{staticClass:"tw-mb-0 tw-italic tw-break-words",attrs:{"title":_vm.roomDescription(room)}},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.roomDescription(room),90))+" ")])])])}),0)])}),_c('NewPropertyRoomQuickEntryModal',{ref:"quickEntryModal",on:{"reload":_vm.loadPropertyRooms}}),_c('PropertyRoomCreateEditModal',{ref:"createEditModal",on:{"reload":_vm.loadPropertyRooms}}),_c('BaseModal',{ref:"levelManagementModal",attrs:{"title":"Niveaubeheer","max-width":"tw-max-w-sm"}},[_c('FormulateForm',{attrs:{"name":"levelManagementForm"},on:{"submit":_vm.updateSelectedPropertyLevels},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"type":"checkbox","name":"level_ids","options":_vm.levelOptions},model:{value:(_vm.selectedLevels),callback:function ($$v) {_vm.selectedLevels=$$v},expression:"selectedLevels"}}),_c('FormulateErrors'),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"outer-class":"tw-mt-8"}},[_c('i',{class:[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]}),_vm._v(" Opslaan ")])]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }