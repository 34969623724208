<template>
  <BaseModal ref="modal" title="Snel aanmaken van ruimtes" max-width="tw-max-w-4xl" @hide="resetForm">
    <FormulateForm
      #default="{ isLoading }"
      v-model="rooms"
      name="roomQuickEntry"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="handleRoomCreate"
    >
      <FormulateInput
        type="group"
        name="roomtypes"
        :repeatable="true"
        validation="required"
        group-repeatable-class="tw-relative tw-shadow-card tw-rounded-md tw-p-4 tw-mb-8"
      >
        <template #default="roomTypeProps">
          <FormulateInput
            name="type"
            label="Type"
            type="select"
            :options="PROPERTY_ROOM_TYPES"
            validation="required"
            outer-class="tw-mb-4 tw-max-w-[200px]"
          />

          <FormulateInput
            type="group"
            name="surfaces"
            label="Voeg ruimtes toe"
            validation="required"
            :show-required-label="true"
            :repeatable="true"
            :label-class="['tw-mb-4']"
            group-repeatable-class="tw-relative tw-shadow-card tw-rounded-md tw-p-4"
            grouping-class="tw-grid sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-8"
            outer-class="tw-my-0"
          >
            <template #default="{ index }">
              <FormulateInput
                :ref="`room_type_index_${roomTypeProps.index}_surface_${index}`"
                type="number"
                name="surface"
                min="0"
                label="Oppervlakte (m²)"
                placeholder="(m²)"
                validation="bail|required|min:0"
                outer-class="tw-my-0"
                @hook:mounted="focusInput(`room_type_index_${roomTypeProps.index}_surface_${index}`)"
              />
            </template>

            <template #addmore="{ addMore }">
              <button
                type="button"
                title="+ Ruimte"
                class="
                  tw-px-2 tw-py-0.5 tw-border tw-mt-6
                  tw-text-success tw-border-success tw-rounded-md tw-shadow-card tw-bg-white
                  hover:tw-bg-success hover:tw-text-white
                  disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed
                "
                @click="addMore"
              >
                <i class="far fa-plus" /> Ruimte
              </button>
            </template>
            <template #remove="{ index, removeItem }">
              <button
                type="button"
                title="Ruimte verwijderen"
                class="
                  tw-px-1.5 tw-py-0.5 tw-border
                  tw-absolute tw-right-0 tw-top-0 tw-translate-x-1/2 tw--translate-y-1/2
                  tw-text-danger tw-border-danger tw-rounded-full tw-shadow-card tw-bg-white
                  hover:tw-bg-danger hover:tw-text-white
                  disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed
                "
                @click="removeItem(index)"
              >
                <i class="fas fa-trash" />
              </button>
            </template>
          </FormulateInput>
        </template>

        <template #addmore="{ addMore }">
          <button
            type="button"
            title="+ Kamertype"
            class="
              tw-px-2 tw-py-0.5 tw-border
              tw-text-success tw-border-success tw-rounded-md tw-shadow-card tw-bg-white
              hover:tw-bg-success hover:tw-text-white
              disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed
            "
            @click="addMore"
          >
            <i class="far fa-plus" /> Kamertype
          </button>
        </template>
        <template #remove="{ index, removeItem }">
          <button
            type="button"
            title="Kamertype en gekoppelde ruimtes verwijderen"
            class="
              tw-px-1.5 tw-py-0.5 tw-border
              tw-absolute tw-right-0 tw-top-0 tw-translate-x-1/2 tw--translate-y-1/2
              tw-text-danger tw-border-danger tw-rounded-full tw-shadow-card tw-bg-white
              hover:tw-bg-danger hover:tw-text-white
              disabled:tw-bg-gray-cc disabled:tw-text-white disabled:tw-border-gray-cc disabled:tw-cursor-not-allowed
            "
            @click="removeItem(index)"
          >
            <i class="fas fa-trash" />
          </button>
        </template>
      </FormulateInput>

      <FormulateErrors class="tw-text-right" />

      <div class="tw-flex tw-justify-end">
        <FormulateInput
          type="submit"
          title="Opslaan"
          :disabled="isLoading"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
      </div>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { focusInput } from '@/utils/helpers'
import { successModal } from '@/modalMessages'
import { addPropertyRoom } from '@/services/properties'
import { PROPERTY_ROOM_TYPES } from '@/forms/selectOptions'

export default {
  name: 'NewPropertyRoomQuickEntryModal',
  constants: {
    PROPERTY_ROOM_TYPES
  },
  data () {
    return {
      rooms: {},
      propertyLevel: null
    }
  },
  methods: {
    focusInput,

    show ({ property_level = null }) {
      this.propertyLevel = property_level
      this.$refs.modal.show()
    },

    resetForm () {
      this.$formulate.reset('roomQuickEntry')
      this.rooms = {}
    },

    async handleRoomCreate (data) {
      try {
        const promises = []
        const propertyId = this.$route.params.id

        data.roomtypes.forEach(roomType => {
          roomType.surfaces.forEach(surface => {
            const payload = {
              property: propertyId,
              type: roomType.type,
              surface: surface.surface,
              property_level: this.propertyLevel
            }
            promises.push(addPropertyRoom(propertyId, payload))
          })
        })

        const response = await Promise.all(promises)
        successModal('Ruimte(s) succesvol aangemaakt')
        this.$emit('reload', propertyId)
        this.$refs.modal.hide()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'roomQuickEntry')
      }
    }
  }
}
</script>
